import css from 'styled-jsx/css';
import colors from '../../config/styles/colors';
import typography from '../../config/styles/typography';

const CategoryTitleStyles = css`
  a {
    text-decoration: none;
  }

  .categoty-title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: auto;
    height: auto;
    background-color: ${colors.white.shade1};
    box-sizing: border-box;
    padding: 10px 19px;
    @mixin tabletToDesktop {
      padding: 25px 0 0 25px;
    }
    &.mobile {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .l1category {
    font-size: ${typography.xl3};
    color: ${colors.gray.shade19};
    font-weight: 300;
    white-space: normal;
    line-height: 2.6rem;
    text-align: left;
    margin-bottom: 3px;
  }

  .l1category:hover {
    text-decoration: underline;
    text-underline-position: under;
  }

  .l2category {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .l2category::first-letter {
    text-transform: capitalize;
  }
`;

export default CategoryTitleStyles;
