import React, { useState, useContext, useEffect } from 'react';
import createContainer from 'constate';
import CookieStorage from './CookieStorage';

function useUserContext() {
  const FIRST_NAME_POSITION = 0;
  const CART_COUNT_POSITION = 1;
  const LAST_NAME_POSITION = 2;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isUserLoggedIn, setUserLoggedIn] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [isVendorLogin, setIsVendorLoggedIn] = useState(false);
  const [vendorData, setVendorData] = useState({});
  const { getCookie } = CookieStorage;

  const refreshUserData = () => {
    const userCookie = CookieStorage.getUserDataCookieName();
    const dynDataCookie = getCookie(userCookie);
    const dynDataParts = dynDataCookie ? dynDataCookie.split('*') : {};
    const cookieFirstName = dynDataParts[FIRST_NAME_POSITION];
    const cookieCartCount = dynDataParts[CART_COUNT_POSITION];
    const cookieLastName = dynDataParts[LAST_NAME_POSITION];
    let vendorDataCookie = getCookie('VENDOR_DATA');
    if (vendorDataCookie) {
      vendorDataCookie = vendorDataCookie.replace(/"/g, '');
      const [vendorId, storeName, storeId, vendorCode] = vendorDataCookie.split('*');
      setIsVendorLoggedIn(true);
      if (!vendorId || !storeId || !vendorCode) return null;
      setVendorData({
        vendorId,
        storeName,
        storeId,
        vendorCode,
      });
    }
    setUserLoggedIn(!!cookieFirstName);
    setFirstName(cookieFirstName);
    setCartCount(cookieCartCount);
    setLastName(cookieLastName);
    return null;
  };

  useEffect(() => {
    refreshUserData();
    return null;
  }, []);

  return {
    firstName,
    setFirstName,
    isUserLoggedIn,
    setUserLoggedIn,
    cartCount,
    setCartCount,
    lastName,
    isVendorLogin,
    vendorData,
    refreshUserData,
  };
}

const UserContext = createContainer(useUserContext);

const withUser = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithUser = (props) => {
    const user = useContext(UserContext.Context);
    return <WrappedComponent {...props} {...user} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithUser.getInitialProps = getInitialProps;
  }
  return ComponentWithUser;
};

export default UserContext;
export { withUser, useUserContext };
