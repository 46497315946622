import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import constants from '../../../config/constants';
import { getImages } from '../../../utils/GetImages';
import _ from '../../../utils/LodashImports';
import { getUniqueColorVariantsSLP } from '../../../utils/variant';
import { getUniqueSizesForSLP } from '../../../utils/variant/getUniqueSizes';
import Prices from '../../Prices/Prices';
import ProductColorSwatches from '../../ProductColorSwatches/ProductColorSwatches';
import SizeButton from '../../Size/SizeButtons/SizeButton/SizeButton';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import Button from '../../ui/Button/Button';
import { CONFIGS } from '../helper';
import { ProductVariationsStyles } from './ProductVariations.style';

const imageAttr = {
  width: 92,
  height: 92,
  quality: 70,
};

const ProductVariations = ({
  labels,
  productOverlayContext,
  appCtx,
  onModalCloseHandler,
  isAddToCartInProgress,
  addProductToCart,
}) => {
  const { deviceType, regionCode, store } = appCtx;
  const isNewPriceApplicable = _.get(appCtx, 'siteConfig.toggles.isNewPriceApplicable', false);
  const isCFMediasEnabled = _.get(appCtx, 'siteConfig.toggles.isCFMediasEnabled', false);

  const [variantId, setVariantId] = useState(productOverlayContext.skuId);
  const [selectedColorSwatchIndex, setColorIndex] = useState(0);
  const [selectedSize, setSelectedSize] = useState(null);
  const [imageURL, setImageUrl] = useState('');

  const isImageDeliveryOverrideEnabled = _.get(appCtx, 'siteConfig.toggles.isImageDeliveryOverrideEnabled', false);

  const viewConfig = CONFIGS[deviceType] || CONFIGS.mobile;
  const handleColorSwatch = (obj, index) => {
    const colorVariantId = obj.extraInfo;
    const url = getImages({
      variantId: isCFMediasEnabled ? 'NoImage' : colorVariantId,
      regionCode,
      attr: imageAttr,
      url: _.get(obj, 'mediaUrls[0]', ''),
      isImageDeliveryOverrideEnabled,
      store,
    });
    setImageUrl(url);
    if (colorVariantId !== variantId) {
      setVariantId(colorVariantId);
      setColorIndex(index);
      setSelectedSize(null);
    }
  };
  useEffect(() => {
    const colorIndex = _.get(productOverlayContext, 'variants[0].options').findIndex(
      (option) => option.extraInfo === productOverlayContext.selectedVariantId
    );
    if (colorIndex > -1) {
      handleColorSwatch(_.get(productOverlayContext, 'variants[0].options')[colorIndex], colorIndex);
    } else {
      const sizeIndex = _.get(productOverlayContext, 'variants[1].options').findIndex(
        (option) => option.mediaId === productOverlayContext.skuId
      );

      let mediaUrl = '';
      if (sizeIndex > -1) {
        mediaUrl = _.get(productOverlayContext, `variants[1].options[${sizeIndex}].mediaUrls[0]`, '');
      }
      const url = getImages({
        variantId: isCFMediasEnabled ? 'NoImage' : _.get(productOverlayContext, 'skuId', 'default'),
        regionCode,
        attr: imageAttr,
        url: mediaUrl,
        isImageDeliveryOverrideEnabled,
        store,
      });

      setImageUrl(url);
    }
  }, []);

  useEffect(() => {
    if (selectedSize) {
      const url = getImages({
        variantId: isCFMediasEnabled ? 'NoImage' : _.get(selectedSize, 'variant', 'default'),
        regionCode,
        attr: imageAttr,
        url: _.get(selectedSize, 'mediaUrls[0]', ''),
        isImageDeliveryOverrideEnabled,
        store,
      });
      setImageUrl(url);
    }
  }, [selectedSize]);

  const { displayName, brand, productId, productType } = productOverlayContext;
  const colorVariants = getUniqueColorVariantsSLP({ id: variantId }, productOverlayContext.variants[0].options);
  const currentColorName =
    selectedColorSwatchIndex > -1 && colorVariants.length ? colorVariants[selectedColorSwatchIndex].label : undefined;
  const sizes = getUniqueSizesForSLP(
    currentColorName ? colorVariants[selectedColorSwatchIndex].sizes : productOverlayContext.variants[1].options,
    currentColorName
  );
  const type =
    productType === constants.PRODUCT_CONSTANTS.CONNECT
      ? constants.PRODUCT_CONSTANTS.CONNECT
      : constants.PRODUCT_CONSTANTS.HARDLINE;
  const cartButton = () => {
    let buttonLabel = _.get(labels, 'SELECT_VARIANT_CHOOSE_OPTIONS_MKP', 'Elige tus opciones');

    if (selectedSize) {
      buttonLabel = _.get(labels, 'ADD_TO_CART_TEXT_MKP', 'Agregar al carro');
    }
    if (isAddToCartInProgress) {
      buttonLabel = labels.ADDING_TO_CART;
    }
    return (
      <Button
        id="add-to-cart"
        disabled={!selectedSize || isAddToCartInProgress}
        size="medium"
        type="mkp-secondary"
        key="select-variant-selected-add-to-cart"
        onClick={() => {
          addProductToCart(
            {
              hasVariations: false,
              productId: productOverlayContext.productId,
              quantity: 1,
              offeringId: selectedSize.offeringId,
              skuId: selectedSize.variant || selectedSize.mediaId,
              colorCode:
                selectedColorSwatchIndex > -1
                  ? _.get(productOverlayContext, `variants[0].options[${selectedColorSwatchIndex}].value`, '')
                  : '',
              size: selectedSize ? selectedSize.value : null,
              colorName:
                selectedColorSwatchIndex > -1
                  ? _.get(productOverlayContext, `variants[0].options[${selectedColorSwatchIndex}].label`, '')
                  : '',
            },
            productOverlayContext
          );
        }}
      >
        {buttonLabel}
      </Button>
    );
  };
  return (
    <div className="popup">
      <div className="header">
        <i className="warning-icon">
          <span className="sr-only">{labels.SELECT_VARIANT_WARNING_MESSAGE}</span>
        </i>

        <span className="label">
          <BodyCopy size="copy10">{labels.SELECT_VARIANT_WARNING_MESSAGE}</BodyCopy>
        </span>

        <button type="button" className="close-button" onClick={onModalCloseHandler}>
          <i className="csicon-danger">
            <span className="sr-only">{labels.CLEAR}</span>
          </i>
        </button>
      </div>
      <div className="body">
        <div className="product-details">
          {imageURL && <img className="product-image" src={imageURL} alt={displayName} />}
          <div className="info-wrapper">
            <div className="item-info">
              {brand && (
                <BodyCopy type={viewConfig.brandName.type} size={viewConfig.brandName.size} isHighlighted>
                  {brand.toUpperCase()}
                </BodyCopy>
              )}
              <BodyCopy type={viewConfig.description.type} size={viewConfig.description.size}>
                {displayName}
              </BodyCopy>
            </div>
            <div
              className={classNames('price', {
                'mt-8px': isNewPriceApplicable,
              })}
            >
              <Prices
                productId={productId}
                priceList={selectedSize ? selectedSize.prices : productOverlayContext.prices}
                variant="CART"
              />
            </div>
          </div>
        </div>
        <div className="variant-details">
          {productOverlayContext.variants[0].options[selectedColorSwatchIndex] && (
            <div className="color-variants">
              <BodyCopy
                isHighlighted
                size={deviceType === 'mobile' ? 'copy1' : 'copy3'}
              >{`${labels.COLOR}: `}</BodyCopy>
              <BodyCopy size={deviceType === 'mobile' ? 'copy1' : 'copy3'}>
                {productOverlayContext.variants[0].options[selectedColorSwatchIndex].label}
              </BodyCopy>
              <ProductColorSwatches
                colorVariants={colorVariants}
                productId={productId}
                selectedColorSwatchIndex={selectedColorSwatchIndex}
                colorSwatchHandler={handleColorSwatch}
                type={type}
                size="medium"
              />
            </div>
          )}
          <div className="size-variants">
            <span className="size-title">{_.get(labels, 'SELECTION_SIZE_MKP', 'Selecciona talla:')}</span>
            <div className="sizes-group">
              {sizes &&
                sizes.map((obj, index) => (
                  <SizeButton
                    value={obj.value}
                    available={obj.available}
                    selectedSize={selectedSize ? selectedSize.value : null}
                    onSizeChange={() => {
                      setSelectedSize(obj);
                    }}
                    index={index}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="addToCart-container-mkp">
          <button type="button" onClick={onModalCloseHandler} className="cerrar-button">
            <span className="cerrar-button-text">{labels.CLEAR}</span>
          </button>
          <div className="action-buttons-mkp">{cartButton()}</div>
        </div>
      </div>
      <style jsx>{ProductVariationsStyles}</style>
    </div>
  );
};

ProductVariations.propTypes = {
  labels: PropTypes.object.isRequired,
  productOverlayContext: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  onModalCloseHandler: PropTypes.func.isRequired,
  isAddToCartInProgress: PropTypes.bool.isRequired,
  addProductToCart: PropTypes.object.isRequired,
};

export default ProductVariations;
