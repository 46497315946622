/* eslint-disable consistent-return */
const setPromotionalProductToAdd =
  ({ cart, setCart }) =>
  ({ promotionalProductsToAdd }) => {
    setCart({
      ...cart,
      promotionalProductsToAdd,
    });
  };

export { setPromotionalProductToAdd };
