import css from 'styled-jsx/css';
import { colors, icons } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const addToCartButtonStyle = css`
  .count-box {
    display: flex;
    height: 40px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid var(--primary-color);
    background: #fefeff;
    color: #333;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .max-qnty {
    color: #8b8b8b;
    font-family: Lato, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    min-width: 136px;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
  .quantity-display {
    min-height: 16px;
    padding-bottom: 14px;
    @mixin mobileToTablet {
      padding-bottom: 0;
      min-height: 40px;
    }
  }
  .add-to-cart {
    display: flex;
    min-width: 136px;
    align-items: center;
    gap: 4px;
    flex-direction: row;
    justify-content: center;
    padding: 1px;

    @mixin desktop {
      padding-bottom: 36px;
    }
    div[hidden] {
      visibility: hidden;
    }
    button[hidden] {
      display: none;
    }
    .remove-from-cart-button {
      width: 40px;
      height: 40px;
      display: flex;
      background-color: var(--primary-color);
      border-radius: 80px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .remove-from-cart-icon {
        width: 20px;
        height: 20px;
        background-image: url('${svgToDataUri(icons.trash)}');
        background-size: contain;
        background-repeat: no-repeat;
      }
      .remove-from-cart-minus-icon {
        width: 20px;
        height: 20px;
        background-image: url('${svgToDataUri(icons.minus)}');
        background-size: contain;
        background-repeat: no-repeat;
      }
      &.disabled {
        background-color: ${colors.gray.shade46};
        cursor: auto;
      }
    }
    .count-from-cart {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 100px;
      border-radius: 4px;
      border: 1px solid var(--primary-color);
    }

    .add-to-cart-button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-color);
      border-radius: 80px;
      cursor: pointer;
      .add-to-cart-icon {
        width: 20px;
        height: 20px;
        background-image: url('${svgToDataUri(icons.addToCartIcon)}');
        background-size: contain;
        background-repeat: no-repeat;
      }
      &.disabled {
        background-color: ${colors.gray.shade46};
        cursor: auto;
      }
    }
  }
`;

export { addToCartButtonStyle };
