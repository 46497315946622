import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import _ from '../../utils/LodashImports';
import { getBannerStyles, waitForDigitalData } from './helpers';

const AdIframe = ({ appCtx, pageType, bannerType }) => {
  const [srcIframe, setSrcIframe] = useState('');
  const [widthBanner, setWidthBanner] = useState(0);
  const [heightBanner, setHeightBanner] = useState(0);

  const adInjectorIsEnabled = _.get(appCtx, 'siteConfig.toggles.adInjectorIsEnabled', false);

  const adsConfig = _.get(appCtx, 'siteConfig.configurations.adsConfig', {});
  const adsConfigResponse = adsConfig;

  useEffect(() => {
    window.addEventListener('message', (event) => {
      const urlAdsConfig = new URL(adsConfigResponse.adProviderUrl || '');

      /* istanbul ignore next */
      if (event.origin === urlAdsConfig.origin && event.data && event.data[`${bannerType}BannerSize`]) {
        const [width, height] = event.data[`${bannerType}BannerSize`];
        setHeightBanner(height + 15);
        setWidthBanner(width + 10);
      }
    });
  }, [bannerType, adsConfigResponse]);

  useEffect(() => {
    if (!adInjectorIsEnabled) return;

    const getQueryParams = async () => {
      await waitForDigitalData();

      if (window.digitalData) {
        const adProviderUrl = _.get(adsConfigResponse, 'adProviderUrl', '');

        const currentBanners = adsConfigResponse[pageType] || [];

        const bannerContainer = {
          top: 'google-banner-superior-div',
          bottom: 'google-banner-inferior-desktop-div',
          left: 'google-banner-izq-div',
        };

        const topBanner = currentBanners.find((banner) => banner.container === bannerContainer[bannerType]);

        const platform = appCtx.deviceType === 'mobile' ? 'mobile' : 'desktop';

        const digitalDataObject = {
          subSection: _.get(window, 'digitalData.page.subSection', undefined),
          subJota: _.get(window, 'digitalData.medalliaParameters.j_sublinea', undefined),
          siteSection: _.get(window, 'digitalData.page.subSection', undefined),
          store_in_store: _.get(appCtx, 'store', 'falabella') || 'falabella',
          searchTerm:
            _.get(window, '__NEXT_DATA__.props.pageProps.searchTerm', undefined) === 'undefined'
              ? undefined
              : _.get(window, '__NEXT_DATA__.props.pageProps.searchTerm', undefined),
          jota: _.get(window, 'digitalData.medalliaParameters.j_linea', undefined),
          gcategory_d: _.get(window, 'digitalData.tag.XLP_Clasification', '').slice(0, 9),
          gcategory_a: _.get(window, 'digitalData.tag.XLP_Clasification', '').slice(0, 3),
          gcategory_b: _.get(window, 'digitalData.tag.XLP_Clasification', '').slice(0, 5),
          gcategory_c: _.get(window, 'digitalData.tag.XLP_Clasification', '').slice(0, 7),
          slot: _.get(topBanner, 'slot', undefined),
          folder: _.get(topBanner, 'folder', undefined),
          emailHash: _.get(window, 'digitalData.login.emailHash', undefined),
          platform,
          bannerType,
        };

        const filteredDigitalDataObject = Object.fromEntries(
          Object.entries(digitalDataObject).filter(([, value]) => !!value)
        );

        const queryParams = new URLSearchParams(filteredDigitalDataObject).toString();

        setSrcIframe(`${adProviderUrl}?${queryParams}`);
      }
    };

    getQueryParams();
  }, [adInjectorIsEnabled, adsConfig, adsConfigResponse, appCtx, pageType, bannerType]);

  const bannerStyles = getBannerStyles(bannerType);

  return (
    <div style={bannerStyles}>
      <center>
        <iframe
          id="AdIframe"
          title="AdIframe"
          width={widthBanner}
          height={heightBanner}
          src={srcIframe}
          scrolling="no"
          allowFullScreen
        />
      </center>
    </div>
  );
};

AdIframe.propTypes = {
  appCtx: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired,
  bannerType: PropTypes.string.isRequired,
};

export { AdIframe };
export default withApplicationContext(AdIframe);
