import PropTypes from 'prop-types';
import React from 'react';
import _ from '../../../utils/LodashImports';
import BodyCopy from '../BodyCopy/BodyCopy';
import Button from '../Button/Button';
import { AddToCartErrorStyles } from './AddToCartError.style';

const AddToCartError = ({ labels, onModalCloseHandler, show401Error, setShowLoginForm, addToCartErrorCode }) => {
  const addToCartErrorMessage = addToCartErrorCode
    ? labels?.[`ADD_TO_CART_ERROR_${addToCartErrorCode}`]
    : labels?.['ADD_TO_CART_ERROR'];

  return (
    <div className="popup">
      <div className="header-and-body">
        <div className="header">
          <i className="warning-icon">
            <span className="sr-only">{_.get(labels, 'NO_PLAN_TITLE_MKP', 'Lo sentimos')}</span>
          </i>
          <span className="label">
            <BodyCopy size="copy10">{_.get(labels, 'NO_PLAN_TITLE_MKP', 'Lo sentimos')}</BodyCopy>
          </span>
          <button type="button" className="close-button" onClick={onModalCloseHandler}>
            <i className="csicon-danger">
              <span className="sr-only">{labels.CLEAR}</span>
            </i>
          </button>
        </div>
        <div className="body">
          {show401Error ? (
            <BodyCopy size="copy3" type="senary">
              {_.get(
                labels,
                'UNAUTHORIZED_ATC_ERROR',
                'Nos dimos cuenta que no estabas ahí y por seguridad cerramos tu sesión, pero no te preocupes, guardamos los producto de tu Bolsa de compra. Vuelve a iniciar sesión.'
              )}
            </BodyCopy>
          ) : (
            <BodyCopy size="copy3" type="senary">
              {addToCartErrorMessage}
            </BodyCopy>
          )}
        </div>
      </div>
      <div className="variant-details mkp">
        <div className="add-to-cart-error">
          <Button
            size="medium"
            type="mkp-secondary"
            key="select-variant-selected-add-to-cart"
            class="hello"
            onClick={() => {
              onModalCloseHandler();
              if (show401Error) {
                setShowLoginForm(true);
              }
            }}
          >
            {_.get(labels, 'ACCEPT_MKP', 'Aceptar')}
          </Button>
        </div>
      </div>

      <style jsx>{AddToCartErrorStyles}</style>
    </div>
  );
};

AddToCartError.defaultProps = {
  show401Error: false,
  addToCartErrorCode: '',
};
AddToCartError.propTypes = {
  labels: PropTypes.object.isRequired,
  onModalCloseHandler: PropTypes.func.isRequired,
  show401Error: PropTypes.bool,
  setShowLoginForm: PropTypes.func.isRequired,
  addToCartErrorCode: PropTypes.string,
};

export default AddToCartError;
