/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withLabels } from '../../../../utils/LabelsContext';
import _ from '../../../../utils/LodashImports';
import { redirect } from '../../../../utils/RouteHandler';
import noop from '../../../../utils/noop';
import useIntersection from '../../../../utils/useIntersection';
import Badges from '../../../Badges/Badges';
import BestSellerRibbon from '../../../BestSellerRibbon/BestSellerRibbon';
import MultipurposeBadge from '../../../MultipurposeBadge/MultipurposeBadge';
import MyLists from '../../../MyLists/MyLists';
import Prices from '../../../Prices/Prices';
import ProdFeatures from '../../../ProdFeatures/ProdFeatures';
import ShippingDetails from '../../../ShippingDetails/ShippingDetails';
import AddToCartButton from '../../../ui/AddToCartButton/AddToCartButton';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import Button from '../../../ui/Button/Button';
import ColorSwatch from '../../../ui/ColorSwatch/ColorSwatch';
import ImageGroup from '../../../ui/ImageGroup/ImageGroup';
import RatingsVisual from '../../../ui/RatingsVisual/RatingsVisual';
import Reviews from '../../../ui/Reviews/Reviews';
import MarketplaceSpinner from '../../../ui/Spinner/MarketplaceSpinner';
import Tab from '../../../ui/Tab/Tab';
import TruncateText from '../../../ui/TruncateText/TruncateText';
import {
  buildPodUrl,
  capitalizeText,
  formatUrl,
  getCallToActionText,
  getSelectedColorIndex,
  getSellerIconClass,
} from '../../podUtils';
import { largeButtonLayouts } from '../helper';
import LinearPodMobileStyles from './LinearPodMobile.style';

const LinearPodMobile = ({
  product,
  labels,
  layout,
  lazyLoadImage,
  buttonLabel,
  clickForProductDetails,
  isAddToCartInProgress,
  isSelected,
  isPodActionButtonEnabled,
  appCtx,
  sponsoredClick,
  handlePodClick,
  isFirstPod,
  redirectToPDP,
  setIsAddToCartInProgress,
}) => {
  const isMultipurposeBadgeApplicable = _.get(appCtx, 'siteConfig.toggles.isMultipurposeBadgeApplicable', false);
  const isMeatStickerApplicable = _.get(appCtx, 'siteConfig.toggles.isMeatStickerApplicable', false);
  const { topSpecifications, badges, meatStickers } = product;
  const hasStickers = isMeatStickerApplicable && !!meatStickers;
  const multipurposeBadges = _.get(product, 'multipurposeBadges', []);
  const [variantId, setVariantId] = useState(product.skuId);
  const [productUrl, setProductURL] = useState('');
  const [hoverState, setHoverState] = useState(false);
  const buttonSize = largeButtonLayouts.includes(layout) ? 'large' : '';
  const isSponsored = _.get(product, 'isSponsored', false);
  const sponsoredText = _.get(labels, 'SPONSORED_TEXT', 'Patrocinado');
  const podRef = useRef();
  const inViewport = useIntersection(podRef, '0px', '0.3', false);

  useEffect(() => {
    if (inViewport) {
      sponsoredClick('impression');
    }
  }, [inViewport]);

  const productHasMediaUrls = product.mediaUrls && product.mediaUrls.length > 0;
  const [hasMediaUrls, setHasMediaUrls] = useState(productHasMediaUrls);
  const productImages = hasMediaUrls ? [product.mediaUrls[0]] : [product.media.id];

  const { onImageHover } = product.media;
  if (onImageHover === 'FETCH_SECOND_IMAGE') {
    if (hasMediaUrls) {
      const onHoverImageIndex = product.mediaUrls.length > 1 ? 1 : 0;
      productImages.push(product.mediaUrls[onHoverImageIndex]);
    } else {
      productImages.push(`${product.media.id}_2`);
    }
  }
  const [finalProductImages, setFinalProductImages] = useState(productImages);
  const [colorIndex, setColorIndex] = useState(getSelectedColorIndex(product));
  const [callToAction, setCallToAction] = useState(getCallToActionText(product));

  useEffect(() => {
    const finalProductUrl = buildPodUrl(product.url, product.skuId);
    setProductURL(finalProductUrl);
  }, []);

  const handleClick = (setPodClickDdOnXlpOnly = false) => {
    sponsoredClick('visit');
    sponsoredClick('click');
    handlePodClick(setPodClickDdOnXlpOnly);
  };

  const handleColorSwatch = (obj, index) => {
    handleClick(true);
    setVariantId(obj.extraInfo);
    const { mediaUrls } = obj;
    const hasMediaUrlsAvailable = mediaUrls.length > 0;
    const images = hasMediaUrlsAvailable ? [mediaUrls[0]] : [obj.mediaId];
    if (onImageHover === 'FETCH_SECOND_IMAGE') {
      if (hasMediaUrlsAvailable) {
        const selectedImageIndex = mediaUrls.length > 1 ? 1 : 0;
        images.push(mediaUrls[selectedImageIndex]);
      } else {
        images.push(`${obj.mediaId}_2`);
      }
    }
    const finalProductUrl = buildPodUrl(obj.url);
    setProductURL(finalProductUrl);
    setHasMediaUrls(hasMediaUrlsAvailable);
    setFinalProductImages(images);
    setColorIndex(index);
  };

  const [activeTab, setActiveTab] = useState({ id: '', content: noop });

  const changeCallToAction = (tab, index) => {
    handleClick(true);
    setActiveTab(tab);
    return tab.id === 'Plan' && index === 1 ? setCallToAction('VIEW_PLAN') : setCallToAction('VIEW_PREPAID');
  };

  const renderTitle = (prod) => {
    return (
      <div>
        <BodyCopy size="title12" isHighlighted type="secondary" id={`testId-pod-brandName-${prod.productId}`}>
          {(cn) => <b className={`${cn} pod-title title-rebrand`}>{prod.brand}</b>}
        </BodyCopy>
        <style jsx>{LinearPodMobileStyles}</style>
      </div>
    );
  };

  const renderSubTitle = (prod) => {
    return (
      <>
        <BodyCopy maxlines={3} size="copy4" id={`testId-pod-displayName-${prod.productId}`}>
          {(cn) => (
            <b id={`testId-pod-displaySubTitle-${prod.productId}`} className={`${cn} pod-subTitle  subTitle-rebrand`}>
              {prod.displayName}
            </b>
          )}
        </BodyCopy>
        <style jsx>{LinearPodMobileStyles}</style>
      </>
    );
  };

  const renderSellerText = (prod) => {
    if (!prod.sellerName) return;
    const sellerClass = getSellerIconClass(prod.sellerName || '');
    return (
      <TruncateText>
        <BodyCopy size="title6" id={`testId-pod-sellerName-${prod.productId}`}>
          {(cn) => (
            <b
              id={`testId-pod-displaySellerText-${prod.productId}`}
              className={`${cn} pod-sellerText seller-text-rebrand`}
            >
              {prod.sellerName ? `Por ${capitalizeText(prod.sellerName)}` : ''}
            </b>
          )}
        </BodyCopy>
        {sellerClass && <span id="seller-icon" className={`${sellerClass}Icon seller-icon`} />}
        <style jsx>{LinearPodMobileStyles}</style>
      </TruncateText>
    );
  };

  const finalProductUrl = buildPodUrl(product.url, variantId);
  return (
    <a
      href={`${formatUrl(callToAction, productUrl)}`}
      className="pod pod-link"
      data-pod="catalyst-pod"
      data-key={product.productId}
      data-category={product.merchantCategoryId}
      data-gsccategory={product.GSCCategoryId}
      data-sponsored={product.isSponsored}
      id={`testId-pod-${variantId}`}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      role="button"
      tabIndex={0}
      onClick={() => handleClick()}
      ref={podRef}
    >
      {product.isBestSeller && <BestSellerRibbon />}
      <div className="section-head">
        <ImageGroup
          layout={layout}
          ids={finalProductImages}
          lazyLoad={lazyLoadImage}
          mouseOver={hoverState}
          width={90}
          height={90}
          className="list-view"
          alt={`${product.brand} - ${product.displayName}`}
          hasMediaUrls={hasMediaUrls}
          isFirstPod={isFirstPod}
        />
        {product.variants.map((variant) => {
          return (
            variant.type === 'COLOR' && (
              <ColorSwatch
                colorVariants={variant.options}
                handleColorSwatch={handleColorSwatch}
                selectedColorIndex={colorIndex}
                productDisplayLink={finalProductUrl}
                productId={product.productId}
                key={product.productId}
                maximum={3}
              />
            )
          );
        })}
        <Badges
          badgeItems={hasStickers ? meatStickers : badges}
          deviceType="mobile"
          layout={layout}
          isSticker={hasStickers}
        />
      </div>
      <div className="link-container">
        <div className="button">
          <MyLists
            className="xlp-container one-column mobile"
            appCtx={appCtx}
            product={{
              productId: product.productId,
              variantId: product.skuId,
              offeringId: product.offeringId,
            }}
            handleClick={handleClick}
          />
        </div>
        <d className="section-body">
          <div className="pod-details">
            <div className="title-ratings-container">
              {renderTitle(product)}
              <div className="pod-rating">
                {product.totalReviews && product.totalReviews > 0 && (
                  <>
                    <RatingsVisual value={Number(product.rating)} productId={product.productId} size="small" />
                    <Reviews value={Number(product.totalReviews)} layout="LIST" />
                  </>
                )}
              </div>
            </div>
            {renderSubTitle(product)}
            {renderSellerText(product)}
          </div>
          {product.viewTemplate === 'CONNECT' && (
            <Tab changeCallToAction={changeCallToAction} productId={product.productId} />
          )}
          {callToAction === 'VIEW_PLAN' ? (
            <div className="tabContent">
              <BodyCopy size="copy3">{activeTab.content()}</BodyCopy>
            </div>
          ) : (
            <Prices
              priceList={product.prices}
              productId={product.productId}
              page="PLP"
              variant="MOBILE_LIST"
              discountBadge={product.discountBadge}
            />
          )}
          {isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 ? (
            <div className="multipurpose-badge">
              <MultipurposeBadge badgeItems={multipurposeBadges} layout={layout} />
            </div>
          ) : (
            <div className="empty-mkp-badge-space" />
          )}
          {product.availability ? (
            <ShippingDetails availability={product.availability} labels={labels} deviceType="mobile" layout={layout} />
          ) : null}
          <div>
            <ProdFeatures features={topSpecifications} />
          </div>
          {appCtx.store === 'to_com' && (
            <AddToCartButton
              isAddToCartInProgress={isAddToCartInProgress}
              addToCart={clickForProductDetails}
              productUrl={productUrl}
              colorIndex={colorIndex}
              product={product}
              setIsAddToCartInProgress={setIsAddToCartInProgress}
            />
          )}
          {appCtx.store !== 'to_com' && isPodActionButtonEnabled && (
            <div className={`pod-action pod-action-${layout} pod-action-rebranding`}>
              <Button
                id={`testId-Pod-action-${product.productId}`}
                type="mkp-primary-medium  primary-theme"
                disabled={!product.prices || product.prices.length === 0 || (isAddToCartInProgress && !isSelected)}
                size={buttonSize}
                onClick={(event) => {
                  if (product.viewTemplate !== 'CONNECT') {
                    event.stopPropagation();
                    handleClick(true);
                  } else {
                    handleClick();
                  }
                  if (isAddToCartInProgress) {
                    return event.preventDefault();
                  }
                  return product.viewTemplate === 'CONNECT' || redirectToPDP
                    ? redirect(formatUrl(callToAction, productUrl))
                    : clickForProductDetails(event, callToAction, productUrl, colorIndex);
                }}
              >
                <div
                  className={`pod-button-add-to-cart ${
                    isAddToCartInProgress && isSelected && 'pod-button-add-to-cart-in-progress'
                  }`}
                >
                  {isAddToCartInProgress && isSelected && (
                    <div className="pod-button-add-to-cart-spinner">
                      <MarketplaceSpinner size="small" color="primary" />
                    </div>
                  )}
                  <div className="btn-text">{callToAction === 'VIEW_PLAN' ? labels.VIEW_PLAN : buttonLabel}</div>
                </div>
              </Button>
            </div>
          )}

          {isSponsored && (
            <div className="patrocinado-pod">
              <div className="patrocinado-title">{sponsoredText}</div>
            </div>
          )}
        </d>
      </div>
      <style jsx>{LinearPodMobileStyles}</style>
    </a>
  );
};

LinearPodMobile.defaultProps = {
  product: {},
  lazyLoadImage: false,
  appCtx: {},
  redirectToPDP: false,
};

LinearPodMobile.propTypes = {
  product: PropTypes.object,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object,
  layout: PropTypes.string.isRequired,
  lazyLoadImage: PropTypes.bool,
  isAddToCartInProgress: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  clickForProductDetails: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isPodActionButtonEnabled: PropTypes.bool.isRequired,
  sponsoredClick: PropTypes.func.isRequired,
  handlePodClick: PropTypes.func.isRequired,
  isFirstPod: PropTypes.bool.isRequired,
  redirectToPDP: PropTypes.bool,
  setIsAddToCartInProgress: PropTypes.object.isRequired,
};

export { LinearPodMobile };
export default withLabels(LinearPodMobile);
