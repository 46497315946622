import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../../../config/constants';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withCart } from '../../../utils/CartContext';
import _ from '../../../utils/LodashImports';
import { addToCartButtonStyle } from './AddToCartButton.style';

const AddToCartButton = ({
  product,
  addToCart,
  colorIndex,
  appCtx,
  cart,
  isAddToCartInProgress,
  setIsAddToCartInProgress,
}) => {
  const { productId } = product;
  const { setBusy } = appCtx;
  const timeputForLoginModalOn401 = _.get(appCtx, 'siteConfig.textDictionary.TIMEOUT_FOR_LOGIN_MODAL', 1000);
  const [hasReachedMaxQty, setHasReachedMaxQty] = useState(false);
  const { cartItems, removeProduct: onRemoveFromCart, limitAlerts } = cart;
  const item = cartItems && cartItems[0] && cartItems[0].productId === productId ? cartItems[0] : {};
  const { totalQuantity = 0, commerceItemId, warrantyOptions, assemblyOptions, cartLineId = '' } = item;
  const [inputVal, setInputVal] = useState(0);
  const maxUnits = product.measurements && product.measurements.maxUnits ? product.measurements.maxUnits : '';
  const maxUnitsCart = maxUnits || 999;
  const minSaleQty = product.measurements && product.measurements.minSaleQty ? product.measurements.minSaleQty : 1;
  const unit = product.measurements && product.measurements.unit ? product.measurements.unit : 'UN';
  useEffect(() => {
    setBusy(isAddToCartInProgress);
  }, [isAddToCartInProgress]);

  useEffect(() => {
    if (cart && cart.cartItems && cart.cartItems[0].productId === productId) {
      setInputVal(cart.cartItems[0].totalQuantity);
      if (cart.cartItems[0].totalQuantity >= maxUnitsCart) {
        setHasReachedMaxQty(true);
      } else {
        setHasReachedMaxQty(false);
      }
    }
  }, [cart]);

  useEffect(() => {
    if (limitAlerts && (limitAlerts[cartLineId] || limitAlerts.basket)) {
      if (limitAlerts[cartLineId]) {
        setHasReachedMaxQty(true);
      }
    }
  }, [limitAlerts]);

  const handleRemoveItem = (currQuantity = 1, disableServicesAndWarranties = false, cb = undefined) => {
    setIsAddToCartInProgress(true);
    const option = {
      appCtx,
    };

    const warrantycartLineId = disableServicesAndWarranties ? '' : _.get(warrantyOptions, 'cartLineId', '');
    const servicecartLineId = disableServicesAndWarranties ? '' : _.get(assemblyOptions, 'cartLineId', '');

    option.products = [
      {
        cartLineId,
        totalQuantity: Number(totalQuantity),
        commerceItemId,
        quantity: currQuantity,
        warrantycartLineId,
        servicecartLineId,
      },
    ];

    onRemoveFromCart(option)
      .then((res) => {
        setIsAddToCartInProgress(false);
        if (res) {
          const errorCode = _.get(res, '[0].code', '');
          if (errorCode === constants.ATC_SESSION_EXPIRED_CODE) {
            setTimeout(() => {
              cart.setShowCart(false);
              cart.setShowLoginForm(true);
            }, timeputForLoginModalOn401);
          }
        } else if (cb) {
          cb();
        }
      })
      .catch(() => {
        setIsAddToCartInProgress(false);
      });
  };
  const handleAddItem = (e) => {
    if (!hasReachedMaxQty) {
      addToCart(e, '', '', colorIndex);
    }
  };
  const roundOffPrice = (value, precision = 2) => {
    const power = 10 ** (precision || 0);
    return Math.round(value * power) / power;
  };
  const displayUnit = () => {
    return `${roundOffPrice(inputVal * minSaleQty)} ${unit}`.toLowerCase();
  };
  return (
    <>
      <div className="add-to-cart">
        <button
          className="remove-from-cart-button"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleRemoveItem();
          }}
          hidden={!inputVal}
        >
          {inputVal > 1 ? <span className="remove-from-cart-minus-icon" /> : <span className="remove-from-cart-icon" />}
        </button>
        <div className="count-box" hidden={!inputVal}>
          {displayUnit()}
        </div>

        <button
          type="button"
          className={`add-to-cart-button ${hasReachedMaxQty ? 'disabled' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAddItem(e);
          }}
        >
          <span className="add-to-cart-icon" />
        </button>
      </div>
      <div className="quantity-display">
        {maxUnits && <div className="max-qnty">{`Máximo ${maxUnits} unidades`}</div>}
      </div>
      <style jsx>{addToCartButtonStyle}</style>
    </>
  );
};

AddToCartButton.defaultProps = {
  cart: {},
};

AddToCartButton.propTypes = {
  product: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
  colorIndex: PropTypes.func.isRequired,
  appCtx: PropTypes.object.isRequired,
  cart: PropTypes.object,
  isAddToCartInProgress: PropTypes.bool.isRequired,
  setIsAddToCartInProgress: PropTypes.bool.isRequired,
};

export { AddToCartButton };
export default withApplicationContext(withCart(AddToCartButton));
