/* eslint-disable default-case */

export const replacePathsToStandalonePaths = (pageName, store) => {
  const pathsToReplace = {
    category: 'lista',
    search: 'buscar',
    collection: 'seleccion',
    brand: 'marca',
    seller: 'vendedor',
  };
  return ['so_com', 'to_com'].includes(store) ? pathsToReplace[pageName] : pageName;
};
