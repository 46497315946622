import { useEffect, useRef, useState } from 'react';

const myFavsTagname = 'Mis favoritos';
const useMyLists = ({
  product,
  isPDPpage,
  myLists: {
    addTolList,
    myListData = [],
    deleteFromList,
    getAllLists,
    setAddedLists: onSuccessAddToList,
    setUndo,
    setAlertType,
    showMyLists,
  },
}) => {
  const [error, setError] = useState('');
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productIsInAnyList, setProductIsInAnyList] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [userSelectedLists, setUserSelectedLists] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  // Below states are used for DD measurement
  const [isAlreadyInList, setIsAlreadyInList] = useState(false);
  const [isCreateListClicked, setIsCreateListClicked] = useState(false);
  const [isListCreated, setIsListCreated] = useState(false);
  const [addedToLists, setAddedToLists] = useState([]);
  const [deletedFromLists, setDeletedFromLists] = useState([]);
  const [alreadyAddedToLists, setAlreadyAddedToLists] = useState([]);

  const resetState = () => {
    onSuccessAddToList([]);
    setError('');
  };

  const existanceEvaluation = ({ target, source }) =>
    source.productId === target.productId && (isPDPpage ? source.variantId === target.variantId : true);

  const buildFavoritesList = () => ({
    tagName: myFavsTagname,
    listLines: [],
  });

  const checkIfCurrentProductIsInAnyList = () => {
    return myListData.some((list) =>
      list.listLines.some((line) => existanceEvaluation({ source: line, target: product }))
    );
  };

  const getCurrentProductLists = (newLists) => {
    return newLists.filter((list) =>
      list.listLines.some((line) => existanceEvaluation({ source: line, target: product }))
    );
  };
  const getListWithCheckedFlag = (onlyListsWithCurrentProduct, allLists) => {
    return allLists.map((list) => {
      const checkedList = onlyListsWithCurrentProduct.find((l) => l.tagName === list.tagName);
      return {
        ...list,
        checked: !!checkedList,
      };
    });
  };

  const sortLists = (listToBeSorted, favList) => {
    const sortedList = listToBeSorted.filter((e) => e.tagName !== myFavsTagname);
    sortedList.unshift(favList);
    return sortedList;
  };
  useEffect(() => {
    const listsCopy = [...myListData];
    const favoritesList = listsCopy.find((currentList) => currentList.tagName === myFavsTagname);
    const newLists = sortLists(listsCopy, favoritesList || buildFavoritesList());
    const itsInAnyList = checkIfCurrentProductIsInAnyList();
    setProductIsInAnyList(itsInAnyList);
    if (itsInAnyList) {
      const filteredLists = getCurrentProductLists(newLists);
      const listWithCheckedFlag = getListWithCheckedFlag(filteredLists, newLists);
      setLists(listWithCheckedFlag);
      setUserSelectedLists(filteredLists);
    } else {
      setLists(newLists);
    }
  }, [myListData, product.variantId, product.productId]);

  const createList = async (tagName, listType) => {
    setLoading(true);
    resetState();
    const resp = await addTolList({ tagName, product, listType });
    if (resp.ok) {
      setIsListCreated(true);
      setAddedToLists([...addedToLists, tagName]);
      setAlertType('success');
      if (onSuccessAddToList) {
        onSuccessAddToList([tagName]);
      }
    } else {
      setError('No hemos podido crear la lista');
    }
    await getAllLists({});
    setLoading(false);
    setModalVisible(false);
    setShowForm(false);
  };

  const onListChange = (list, isSelected) => {
    setIsSaveButtonEnabled(true);
    if (isSelected) {
      const newList = [...userSelectedLists, ...[list]];
      setUserSelectedLists(newList);
    } else {
      const newList = userSelectedLists.filter((l) => l.tagName !== list.tagName);
      setUserSelectedLists(newList);
    }
  };

  const undo = (undos, updatedLists) => async () => {
    const performUndos = undos.reduce((promises, { tagName, product: listProduct, listType, action }) => {
      if (action === 'add') {
        promises.push(addTolList({ tagName, product, listType }));
      }
      if (action === 'delete') {
        const list = updatedLists.find((l) => l.tagName === tagName);
        const line = list.listLines.find((l) =>
          existanceEvaluation({
            target: l,
            source: listProduct,
          })
        );
        if (line) {
          promises.push(deleteFromList(list.id, line.listLineId, list.tagName));
        }
      }
      return promises;
    }, []);
    await Promise.all(performUndos);
    await getAllLists({});
    setUndo();
  };

  const getActions = () =>
    lists.reduce(
      (actions, currentlist) => {
        const isSelected = userSelectedLists.find(
          (userSelectedList) => userSelectedList.tagName === currentlist.tagName
        );
        const list = lists.find((l) => l.tagName === currentlist.tagName);
        const line = list.listLines.find((l) =>
          existanceEvaluation({
            target: l,
            source: product,
          })
        );
        if (isSelected && !list.checked) {
          setAddedToLists([...addedToLists, currentlist.tagName]);
          actions.dos.push(
            addTolList({
              tagName: currentlist.tagName,
              product,
              listType: currentlist.type,
            })
          );
          actions.undos.push({
            tagName: list.tagName,
            product,
            action: 'delete',
            listType: list.type,
          });
        }
        if (!isSelected && list.checked) {
          if (line) {
            setDeletedFromLists([...deletedFromLists, list.tagName]);
            actions.dos.push(deleteFromList(list.id, line.listLineId, list.tagName));
            actions.undos.push({
              tagName: list.tagName,
              product,
              action: 'add',
              listType: list.type,
            });
          }
        }
        return actions;
      },
      { dos: [], undos: [] }
    );

  const onSaveLists = async () => {
    setLoading(true);
    resetState();
    const actions = getActions();
    const response = await Promise.all(actions.dos);
    const parsedResponse = response.reduce(
      (acc, currentResponse) => {
        if (currentResponse.ok) {
          return {
            ...acc,
            succeeded: [...acc.succeeded, ...[currentResponse.tagName]],
          };
        }
        return {
          ...acc,
          errored: [...acc.errored, ...[currentResponse.tagName]],
        };
      },
      {
        succeeded: [],
        errored: [],
      }
    );
    const deleteHasBeenPerformed = actions.undos.some((element) => {
      return element.action === 'add';
    });
    setAlertType(deleteHasBeenPerformed ? 'warning' : 'success');
    onSuccessAddToList(parsedResponse.succeeded);
    const checkoutResponse = await getAllLists({});
    if (checkoutResponse.ok) {
      const cb = undo(actions.undos, checkoutResponse.data.lists);
      setUndo(() => cb);
    }
    setLoading(false);
    setModalVisible(false);
  };

  const validateList = (newTagName) => {
    const existingList = lists.find((l) => l.tagName.toLowerCase() === newTagName.toLowerCase());
    if (existingList) {
      setError('Ya creaste una lista con este nombre');
    } else {
      setError('');
    }
  };

  useEffect(() => {
    if (showForm) setIsCreateListClicked(true);
  }, [showForm]);

  const handleMLMeasusremnets = () => {
    const listWiseData = lists
      .map((list, i) => {
        const alreadyAddedToCurrentList = alreadyAddedToLists.indexOf(list.tagName) > -1;
        const addedToCurrentList = addedToLists.indexOf(list.tagName) > -1;
        const deletedFromCurrentList = deletedFromLists.indexOf(list.tagName) > -1;
        let listStatus = `Do not save in ${i === 0 ? 'favoritos' : `L${i + 1}`}`;
        if (alreadyAddedToCurrentList) listStatus = `Already added in ${i === 0 ? 'favoritos' : `L${i + 1}`}`;
        if (addedToCurrentList) listStatus = `Save in ${i === 0 ? 'favoritos' : `L${i + 1}`}`;
        if (deletedFromCurrentList) listStatus = `Deleted from ${i === 0 ? 'favoritos' : `L${i + 1}`}`;
        return listStatus;
      })
      .join(' | ');
    const alreadyAddedData = isAlreadyInList ? 'Product already added' : 'Product not added';
    const createListClickData = isCreateListClicked ? 'Click crear nueva lista' : 'Do not click crear nueva lista';
    const listCreatedData = isListCreated ? 'Guardar nueva lista' : 'Do not save nueva lista';
    const DDString = `${product.productId} | ${alreadyAddedData} | ${listWiseData} | ${createListClickData} | ${listCreatedData}`;
    const eventToDigitalData = new CustomEvent('DDXLPMyListsMeasurement', {
      bubbles: true,
      detail: {
        payload: DDString,
      },
    });
    window.dispatchEvent(eventToDigitalData);
    setIsAlreadyInList(false);
    setIsCreateListClicked(false);
    setIsListCreated(false);
    setAddedToLists([]);
    setDeletedFromLists([]);
    setAlreadyAddedToLists([]);
  };

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (modalVisible && productIsInAnyList) {
      setIsAlreadyInList(productIsInAnyList);
      setAlreadyAddedToLists(userSelectedLists.map((list) => list.tagName));
    }
    if (!modalVisible) handleMLMeasusremnets();
  }, [modalVisible]);

  return {
    createList,
    lists,
    productIsInAnyList,
    modalVisible,
    setModalVisible,
    onListChange,
    onSaveLists,
    loading,
    userSelectedLists,
    error,
    validateList,
    showMyLists,
    onSuccessAddToList,
    showForm,
    setShowForm,
    isSaveButtonEnabled,
    getActions,
    undo,
  };
};

export default useMyLists;
