/* eslint-disable no-nested-ternary */
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import { checkboxStyles } from './Checkbox.style';

const Checkbox = (props) => {
  const {
    isChecked,
    onChange,
    id,
    children,
    variant,
    disableClick,
    inConnect,
    type,
    selectedBorderClass,
    normalBorderClass,
    inFloorCalculator,
    isShippingFacet,
    disabled,
    isPrimaryTheme,
    checkBoxIcon,
    appCtx,
  } = props;
  const isTottus = appCtx.store === 'to_com';
  const dashedId = id.replace(/ /g, '-');

  const renderCheckIcon = () => {
    if (isChecked) {
      return (
        <>
          <i
            id={`${dashedId}-icon`}
            className={cn(`csicon-${type}`, {
              'in-floor-calc': inFloorCalculator,
              [checkBoxIcon]: checkBoxIcon,
              shipping: isShippingFacet,
            })}
          />
          <style jsx>{checkboxStyles}</style>
        </>
      );
    }
    return null;
  };
  const handleOnChange = (e) => {
    e.preventDefault();
    onChange({
      isSelected: !isChecked,
    });
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      // Spacebar or Enter keys
      handleOnChange(e);
    }
  };
  const titleClass = inConnect ? 'connect-title' : 'title';
  const renderCheckBox = () => {
    if (isShippingFacet) {
      return (
        <>
          {children}
          <div className="shipping-label">
            <input id={dashedId} type="checkbox" checked={isChecked} />
            <span
              id={`${dashedId}-span`}
              className={`${titleClass} shipping ${isTottus && 'tottus'} ${
                isShippingFacet && 'hasTimeConstraint'
              } ${variant}${selectedBorderClass}${normalBorderClass} ${inFloorCalculator ? 'in-floor-calc' : ''}`}
            />
            {renderCheckIcon()}
          </div>
          <style jsx>{checkboxStyles}</style>
        </>
      );
    }
    return (
      <>
        <input id={dashedId} type="checkbox" checked={isChecked} />
        <span
          className={`${titleClass} ${isShippingFacet && 'hasTimeConstraint'} ${isTottus && 'tottus'}
          ${variant}${selectedBorderClass}${normalBorderClass} ${inFloorCalculator ? 'in-floor-calc' : ''}`}
        >
          {renderCheckIcon()}
          {children}
        </span>
        <style jsx>{checkboxStyles}</style>
      </>
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label
      id={`${dashedId}-wrapper`}
      className={`checkbox ${disabled ? ' disabled' : ''} ${isShippingFacet ? 'shipping' : ''}`}
      htmlFor={dashedId}
      onClick={disableClick ? noop : handleOnChange}
      onKeyDown={disableClick ? noop : handleOnKeyDown}
    >
      {renderCheckBox()}
      <style jsx>{`
         {
          --checkbox-color: ${isTottus ? 'white' : isPrimaryTheme ? 'var(--primary-color)' : 'var(--secondary-color)'};
          --checkbox-rebranded: ${isPrimaryTheme
            ? 'var(--primary-checkbox-rebranded)'
            : 'var(--secondary-checkbox-rebranded)'};
          --checkbox-round-rebranded: ${isPrimaryTheme
            ? 'var(--primary-round-checkbox-rebranded)'
            : 'var(--secondary-round-checkbox-rebranded)'};
        }
      `}</style>
      <style jsx>{checkboxStyles}</style>
    </label>
  );
};

Checkbox.defaultProps = {
  children: null,
  onChange: noop,
  isChecked: false,
  variant: 'secondary',
  disableClick: false,
  inConnect: false,
  type: 'succes',
  selectedBorderClass: '',
  normalBorderClass: '',
  inFloorCalculator: false,
  isShippingFacet: false,
  disabled: false,
  isPrimaryTheme: false,
  checkBoxIcon: '',
};

Checkbox.propTypes = {
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'services']),
  disableClick: PropTypes.bool,
  inConnect: PropTypes.bool,
  type: PropTypes.oneOf('succes', 'mkp-succes', 'mkp-bundle-succes'),
  selectedBorderClass: PropTypes.oneOf(' mkp-border', ' mkp-border-bundle', ''),
  normalBorderClass: PropTypes.oneOf(' mkp-normal-border', ''),
  inFloorCalculator: PropTypes.bool,
  isShippingFacet: PropTypes.bool,
  disabled: PropTypes.bool,
  isPrimaryTheme: PropTypes.bool,
  checkBoxIcon: PropTypes.string,
  appCtx: PropTypes.object.isRequired,
};

export default withApplicationContext(Checkbox);
