import React from 'react';
import constants from '../../../config/constants';
import _ from '../../../utils/LodashImports';
import { navigateWithUrlForCategory } from '../../../utils/RouteHandler';
import FacetsPriceRange from '../../FacetsPriceRange/FacetsPriceRange';
import Multiselect from '../../ui/Multiselect/Multiselect';
import { MultiselectForColor } from '../../ui/MultiselectForColor/MultiselectForColor';
import SingleSelect from '../../ui/SingleSelect/SingleSelect';
import FacetWithMiniPods from '../FacetWithMiniPods/FacetWithMiniPods';
import FacetWithSwitch from '../FacetsWithSwitch/FacetWithSwitch/FacetWithSwitch';
import ShippingFacet from '../ShippingFacet/ShippingFacet';
import VerMasFacet from '../VerMasFacet/VerMasFacet';
import { resetDigitalData } from './digitalData';

const generateFilterUrl = (filters = [], ignoreFilters = []) => {
  return filters
    .filter((filter) => ignoreFilters.indexOf(filter.name) === -1)
    .map((filter) => {
      return `${filter.name}=${filter.value}`;
    })
    .join('&');
};
const urlNavigator = ({
  baseFacetsURL,
  url,
  isCategory,
  tenant,
  hideMenuFacets,
  appCtx,
  value,
  store,
  regionCode,
  changeURL,
}) => {
  const urls = [baseFacetsURL];
  if (url) {
    urls.push(url);
  }
  if (isCategory) {
    navigateWithUrlForCategory({
      rootUrl: '/category',
      site: tenant,
      url: urls.join('&'),
      onRouteChange: () => {
        hideMenuFacets();
        appCtx.setBusy(true);
      },
      categoryId: value.id,
      categoryName: value.title,
      store,
      regionCode,
    });
  } else {
    changeURL(urls.join('&'));
  }
};
const facetsWithSwitch = (params, item, facet) => {
  let content;
  const isFacetWithSwitchVisible = _.get(params.appCtx, 'siteConfig.toggles.isFacetWithSwitchVisible', false);
  const isEventSwitchVisible = _.get(params.appCtx, 'siteConfig.toggles.isEventToggleVisible', false);
  if (isFacetWithSwitchVisible && constants.FREE_SHIPPING_REGEX.test(item.name)) {
    content = (
      <FacetWithSwitch
        item={facet}
        onFacetChange={params.onFacetChange}
        withIcon
        type="freeShipping"
        appCtx={params.appCtx}
      />
    );
  }
  if (isEventSwitchVisible && constants.EVENT_REGEX.test(item.type)) {
    content = <FacetWithSwitch item={facet} onFacetChange={params.onFacetChange} type="event" appCtx={params.appCtx} />;
  }
  return content;
};
const facets = (params, item, facet, isCategory) => {
  const { siteConfig, deviceType, store, regionCode } = params.appCtx;

  const isFacetCountHidden = _.get(siteConfig, 'toggles.isFacetCountHidden', false);
  const isShippingFacetVisible = _.get(siteConfig, 'toggles.isShippingFacetVisible', false);
  const unit = _.get(item, 'meta[0].attributes.unit', false);

  let content;

  const checkboxType = 'mkp-succes';
  const normalBorderClass = ' mkp-normal-border';
  const selectedBorderClass = store === 'to_com' ? 'tottus' : ' mkp-border';

  switch (item.type) {
    case 'MULTI_SELECT_WITH_RANGE':
      content = (
        <>
          {facet.meta && facet.meta.length !== 0 && (deviceType === 'desktop' || store === 'to_com') && (
            <FacetsPriceRange
              priceRangeErrorLabel={params.priceRangeErrorLabel}
              priceRangeNoResultsLabel={params.priceRangeNoResultsLabel}
              priceUnit={unit}
              items={facet.meta}
              onFacetChange={params.onFacetChange}
              onFacetClicked={({ url }) => {
                const urls = [params.baseFacetsURL];
                if (url) {
                  urls.push(url);
                }
                params.changeURL(urls.join('&'));
              }}
            />
          )}

          <Multiselect
            item={facet}
            onFacetChange={params.onFacetChange}
            unit={unit}
            checkboxType={checkboxType}
            selectedBorderClass={selectedBorderClass}
            normalBorderClass={normalBorderClass}
            isFacetCountHidden={isFacetCountHidden}
          />
        </>
      );
      break;

    case 'MULTI_SELECT_WITH_RATINGS':
      content = (
        <Multiselect
          item={facet}
          type="star"
          onFacetChange={params.onFacetChange}
          checkboxType={checkboxType}
          selectedBorderClass={selectedBorderClass}
          normalBorderClass={normalBorderClass}
          isFacetCountHidden={isFacetCountHidden}
        />
      );
      break;
    case 'SINGLE_SELECT_WITH_RATINGS':
      content = (
        <Multiselect
          item={facet}
          type="star"
          onFacetChange={params.onFacetChange}
          checkboxType={checkboxType}
          selectedBorderClass=" mkp-round-selected-border"
          normalBorderClass=" mkp-round-border"
          checkBoxIcon="round-checkbox"
          isFacetCountHidden={isFacetCountHidden}
        />
      );
      break;

    case 'MULTI_SELECT_WITH_COLOR':
      content = (
        <MultiselectForColor
          item={facet}
          onFacetChange={params.onFacetChange}
          isFacetCountHidden={isFacetCountHidden}
        />
      );
      break;

    case 'SINGLE_SELECT_AS_LINK':
      if (item.name === constants.CATEGORY) {
        content = (
          <FacetWithMiniPods
            onFacetChange={(url, value) => {
              urlNavigator({
                ...params,
                url,
                value,
                store,
                regionCode,
                isCategory,
              });
              resetDigitalData();
            }}
            item={facet}
          />
        );
      } else {
        content = (
          <SingleSelect
            onFacetChange={(url, value) => {
              urlNavigator({
                ...params,
                url,
                value,
                store,
                regionCode,
                isCategory,
              });
              resetDigitalData();
            }}
            item={facet}
            site={params.tenant}
            isFacetCountHidden={isFacetCountHidden}
          />
        );
      }
      break;
    case 'MULTI_SELECT_WITH_AVAILABILITY':
      content =
        isShippingFacetVisible && constants.SHIPPING_GROUP_REGEX.test(facet.group) ? (
          <ShippingFacet
            groupType={params.groupType}
            item={facet}
            onFacetChange={params.onFacetChange}
            searchInFacetsLabel={params.searchInFacetsLabel}
            checkboxType={checkboxType}
            selectedBorderClass={selectedBorderClass}
            normalBorderClass={normalBorderClass}
            isFacetCountHidden
          />
        ) : (
          <Multiselect
            groupType={params.groupType}
            item={facet}
            onFacetChange={params.onFacetChange}
            searchInFacetsLabel={params.searchInFacetsLabel}
            checkboxType={checkboxType}
            selectedBorderClass={selectedBorderClass}
            normalBorderClass={normalBorderClass}
            isFacetCountHidden={isFacetCountHidden}
          />
        );
      break;
    default:
      if (item.name !== 'Marca') {
        content = (
          <Multiselect
            groupType={params.groupType}
            item={facet}
            onFacetChange={params.onFacetChange}
            searchInFacetsLabel={params.searchInFacetsLabel}
            checkboxType={checkboxType}
            selectedBorderClass={selectedBorderClass}
            normalBorderClass={normalBorderClass}
            isFacetCountHidden={isFacetCountHidden}
          />
        );
      } else {
        content = (
          <VerMasFacet
            facet={facet}
            xlpName={params.pageName}
            onFacetChange={params.onFacetChange}
            selectedFacetsMenu={params.selectedFacetsMenu}
            isFacetCountHidden={isFacetCountHidden}
            getUpdatedFacets={params.getUpdatedFacets}
          />
        );
      }
      break;
  }
  return content;
};
const getFacetsData = (params) => (item) => {
  const isCategory = params.pageName === 'category';
  let facetContent;
  const facet = { ...item };
  facet.values = facet.values.map((value) => {
    return {
      ...value,
      url: generateFilterUrl(value.filters, isCategory ? [constants.FACETS.CATEGORY_PATH_SEARCH] : []),
    };
  });
  facet.content = () => null;
  facet.title = item.name;
  facet.state = item.state === 'OPEN' && !params.isMobileFacetUI;
  if (params.isMobileFacetUI) {
    facet.iconOptions = {
      up: 'csicon-arrow_up_mobile',
      down: 'csicon-arrow_down_mobile',
      styles: {
        paddingRight: '9px',
      },
    };
  }
  /*
  Below we are setting the facet state (opened/closed)
  if the requests are from only mobile.
  */
  if (params.selectedFacet === facet.name) {
    facet.state = item.state;
  }
  if (params.isMobileFacetUI) {
    let count = 0;
    facet.values.forEach((value) => {
      if (value.selected) {
        count += 1;
      }
    });
    facet.selectedCount = count;
  }
  if (params.groupType === 'facetsWithSwitch' || params.isMobileFacetUI) {
    facetContent = facetsWithSwitch(params, item, facet);
    if (facetContent) {
      facet.state = true;
      facet.content = () => facetContent;
      return facet;
    }
  }
  facetContent = facets(params, item, facet, isCategory);
  if (facetContent) {
    facet.content = () => facetContent;
    return facet;
  }
  return facet;
};

export default getFacetsData;
export { generateFilterUrl };
