import _ from '../../utils/LodashImports';

const getBannerStyles = (bannerType) => {
  if (bannerType === 'left')
    return {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      marginBottom: '5px',
      position: 'sticky',
      top: '0px',
    };

  return {};
};

/* istanbul ignore next */
const waitForDigitalData = () => {
  return new Promise((resolve) => {
    const checkExist = setInterval(() => {
      const xlpClasification = _.get(window, 'digitalData.tag.XLP_Clasification', undefined);

      if (xlpClasification) {
        clearInterval(checkExist);
        resolve();
      }
    }, 300);
  });
};

export { getBannerStyles, waitForDigitalData };
