import apiConfig from '../../../config/api/apiConfig';
import httpService from '../../httpService';
import Logger from '../../Logger';
import { getCommerceHeaders } from '../../tenantHelper';

const addToListCommerce = async ({ appCtx, tagName, product, listType = 'MY_FAVORITES' }) => {
  const { regionCode, priceGroupId, politicalId } = appCtx;
  const listLines = [
    {
      item: {
        productId: product.productId,
        variantId: product.variantId,
        offeringId: product.offeringId,
        sellerId: product.sellerId,
      },
      listLineNumber: product.offeringId,
      quantity: {
        quantityNumber: '1',
        quantityUnit: 'C/U',
        actualValue: '1',
        minSaleUnit: '1',
        maxSaleUnit: '1',
      },
      priceGroup: priceGroupId,
    },
  ];

  const reqBody = {
    data: {
      list: {
        listLines,
      },
    },
    metadata: {
      politicalAreaId: politicalId,
      priceGroup: priceGroupId,
    },
  };

  const additionalHeaders = getCommerceHeaders(regionCode, true, appCtx.url, appCtx.store);

  const url = new URL(apiConfig.addToListCommerceUrl(regionCode));
  url.searchParams.set('listType', listType);
  url.searchParams.set('tagName', tagName);

  const response = await httpService().post(
    url,
    reqBody,
    {
      credentials: 'include',
    },
    additionalHeaders,
    'json'
  );
  const defaultResponse = {
    tagName,
  };
  if (response.statusCode === 200) {
    return { ...defaultResponse, data: response.data, ok: true };
  }
  Logger.error(`Failed to Add to list`);

  if (response.statusCode === 401) {
    return {
      ...defaultResponse,
      ok: false,
      error_code: 'CHECKOUT_INVALID_SESSION',
    };
  }
  return { ...defaultResponse, ok: false, error_code: 'UNKNOWN' };
};

export default addToListCommerce;
